import './App.css';
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {

    const test2 = () => {
        const a = toast.loading('Тест уведомления 2', { position: 'bottom-left' });

        setTimeout(() => {
            toast.update(a, {
                render: `Тест уведомления 2`,
                type: 'error',
                closeButton: true,
                isLoading: false,
                position: 'top-center',
                autoClose: 10000,
            });
        }, 1000)
    }

  return (
      <>
        <ToastContainer position='top-center' autoClose={10000} />
        <div className="App">
            <header className="App-header">


            <p className="App-link" onClick={() => toast.success('Тест уведомления 1')}>
                Открыть уведомление вариант 1
            </p>
            <p className="App-link" onClick={() => test2()}>
                Открыть уведомление вариант 2
            </p>
            </header>
        </div>
      </>
  );
}

export default App;
